import React from 'react'
import { graphql } from 'gatsby'

import Main from '@templates/Main'
import TextBox from '@atoms/TextBox'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SectionWhite from '@molecules/SectionWhite'
import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const AboutPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="About ideolo" center>
          <h2>
            New way to gather
            <br />
            ideas and feedback
          </h2>
          <p>Proudly developed in Warsaw, Poland</p>
        </TextBox>
      </SectionGrayMiddle>
      <SectionWhite>
        <h2>Our mission</h2>
        <h3>Our mission</h3>
        <p>Our mission</p>
        <h2>Our principles</h2>
        <h3>Our principles</h3>
        <p>Our principles</p>
        <h2>Our vision</h2>
        <h3>Our vision</h3>
        <p>Our vision</p>
      </SectionWhite>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query AboutPageQuery {
    pageData: datoCmsAboutPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default AboutPage
